import { ENV, LOGGER_NAMESPACE } from '@lumiere/env'
import logger from '@lumiere/logger'
import { BrowserConsoleTransport } from '@lumiere/logger/transports/BrowserConsoleTransport'
import { SentryBrowserTransport } from '@lumiere/logger/transports/SentryBrowserTransport'

const transports = []

if (ENV !== 'local') {
  transports.push(SentryBrowserTransport())
}

// add browser console transport if not in production, unless logger is turned on
if (ENV !== 'prod' || localStorage.getItem('logger') === 'on') {
  transports.push(BrowserConsoleTransport())
}

logger.init({
  enabled: true,
  namespace: LOGGER_NAMESPACE,
  context: {},
  transports,
})
