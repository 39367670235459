import { getNamespaceColor } from '../getNamespaceColor'
import { type LoggerTransport, type Severity } from '../types'

function isLoggerDisabled() {
  try {
    return localStorage?.getItem('logger') === 'off'
  } catch (_) {
    return false
  }
}
export function BrowserConsoleTransport(): LoggerTransport {
  const getColor = getNamespaceColor([
    'red',
    'green',
    'blue',
    'magenta',
    'orange',
    'purple',
    'brown',
    'teal',
    'indigo',
    'violet',
  ])

  const consoleMethodMap: Record<Severity, (...args: any[]) => void> = {
    DEFAULT: console.log,
    WARNING: console.warn,
    DEBUG: console.log,
    INFO: console.info,
    ERROR: console.error,
    CRITICAL: console.error,
  }
  let browserLoggerEnabled = !isLoggerDisabled()

  return ({ severity, namespace, data, message }) => {
    if (browserLoggerEnabled) {
      consoleMethodMap[severity](
        '%c' + `${namespace}`,
        `color: ${getColor(namespace)}`,
        message,
        data,
      )
    }
  }
}
