import * as Sentry from '@sentry/sveltekit'
import type { SeverityLevel } from '@sentry/sveltekit'
import { type LoggerTransport, type Severity } from '../types'

const severityLevelMap: Record<Severity, SeverityLevel> = {
  DEFAULT: 'log',
  DEBUG: 'debug',
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error',
  CRITICAL: 'fatal',
}

export function SentryBrowserTransport(): LoggerTransport {
  return ({ severity, namespace, data, message }) => {
    Sentry.addBreadcrumb({
      level: severityLevelMap[severity],
      data,
      message: `[${namespace}] ${message}`,
    })
  }
}
