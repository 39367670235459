import { match as channelId } from "../../../src/params/channelId.ts";
import { match as folderId } from "../../../src/params/folderId.ts";
import { match as insights } from "../../../src/params/insights.ts";
import { match as output } from "../../../src/params/output.ts";
import { match as questions } from "../../../src/params/questions.ts";
import { match as scenes } from "../../../src/params/scenes.ts";
import { match as share } from "../../../src/params/share.ts";
import { match as step } from "../../../src/params/step.ts";
import { match as videoId } from "../../../src/params/videoId.ts";

export const matchers = { channelId, folderId, insights, output, questions, scenes, share, step, videoId };