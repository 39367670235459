import * as Sentry from '@sentry/sveltekit'
import '$lib/services/logger'
import { ENV, PROJECT_ID, VERSION } from '@lumiere/env'
import logger from '@lumiere/logger'
import type { RequestEvent } from '@sveltejs/kit'

logger.log('env', { ENV, VERSION, PROJECT_ID })

Sentry.init({
  dsn: 'https://2ecb3c2a8aa44ab397c10d5909a76647@o221024.ingest.sentry.io/6729597',
  tracesSampleRate: 1,
  replaysSessionSampleRate: 0.3,
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  environment: ENV,
  release: VERSION,
  enabled: ENV !== 'local' && !import.meta.env.CYPRESS,
  sampleRate: import.meta.env.CYPRESS ? 0 : 1,
})

export const handleError = Sentry.handleErrorWithSentry(
  ({ error, event }: { error: unknown; event: RequestEvent }) => {
    logger.error('client error', { error, event })
    Sentry.captureException(error, { extra: { event } })
  },
)
